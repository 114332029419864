<template>
    <v-container class="mt-16" fluid>
        <edit-profile-dialog
            v-models:show="dialogs.edit_profile_dialog.show"
            :user_profile="employee"
            @refresh="update_profile($event)"
        />
        <change-language-dialog
            ref="change_language_dialog"
            v-models:show="dialogs.change_language_dialog.show"
        ></change-language-dialog>

        <v-row >
            <v-col cols="12">
                <v-card class="mt-4">
                    <v-card-text class="d-flex justify-center">
                        <v-img
                            class="rounded-circle elevation-4 d-inline-block"
                            src="../../../assets/profile.png"
                            style="max-width: 128px; margin-top: -80px;"
                        />
                    </v-card-text>
                    <v-divider></v-divider>

                    <v-list two-line subheader>
                        <v-subheader>{{ $tr("User Information") }}</v-subheader>

                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>{{ $tr("Name") }}:</v-list-item-subtitle>
                                <v-list-item-title>{{ employee.name }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-divider></v-divider>

                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>{{ $tr("Username") }}:</v-list-item-subtitle>
                                <v-list-item-title>{{ employee.user_name }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>

                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>{{ $tr("Role") }}:</v-list-item-subtitle>
                                <v-list-item-title>{{ $tr(role_map[employee.role]) }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>

                    <v-divider></v-divider>

                    <v-list subheader flat>
                        <v-subheader>{{ $tr("Settings") }}:</v-subheader>

                        <v-list-item-group multiple>
                            <v-list-item @click="$router.push({name: 'SalesRepTasksLog'})">
                                <v-list-item-icon>
                                    <v-icon color="grey">
                                        mdi-history
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $tr("Tasks History") }}</v-list-item-title>
                                    <v-list-item-subtitle
                                        >{{ $tr("view tasks history") }}.</v-list-item-subtitle
                                    >
                                </v-list-item-content>
                            </v-list-item>
                            
                            
                            
                            <v-list-item @click="dialogs.edit_profile_dialog.show = true">
                                <v-list-item-icon>
                                    <v-icon color="grey">
                                        mdi-pen
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $tr("Edit") }}</v-list-item-title>
                                    <v-list-item-subtitle
                                        >{{ $tr("Change profile information") }}.</v-list-item-subtitle
                                    >
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item @click="show_change_language_dialog">
                                <v-list-item-icon>
                                    <v-icon color="grey">
                                        mdi-translate
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $tr("Language") }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ $tr("Change Display Language") }}.</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="logout">
                                <v-list-item-icon>
                                    <v-icon color="grey">
                                        mdi-export
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $tr("Logout") }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ $tr("logout of your profile") }}.</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { sync } from "vuex-pathify";

export default {
    data() {
        return {
            dialogs: {
                edit_profile_dialog: {
                    show: false,
                },
                change_language_dialog: {
                    show: false,
                },
            },
            actions: {
                loading: false,
            },
            role_map: {
                sr: "Sales-Rep",
                mr: "Marketing-Rep",
                mr_admin: "Marketing-Reps Admin",
                sr_admin: "Sales-Reps Admin",
                admin: "Admin",
            },
        };
    },
    computed: {
        ...sync("user", {
            loading_cover: "visualization@loading_cover",
            employee: "user",
        }),
    },
    methods: {
        async update_profile(user) {
            console.log("updated user", user);
            for (const key in user) {
                this.employee[key] = user[key];
            }
            await this.$store.dispatch("user/update_localstorage", null, { root: true });
        },
        async logout() {
            this.$set(this.loading_cover, "fetching_sr_tasks", true);
            await this.$store.dispatch("user/logout", null, { root: true });
            this.$set(this.loading_cover, "fetching_sr_tasks", false);
        },

        show_change_language_dialog() {
            this.$refs.change_language_dialog.show = true;
        },
    },
    props: {},
    components: {
        ChangeLanguageDialog: () => import("./ChangeLanguageDialog/Index.vue"),
        EditProfileDialog: () => import("./EditProfileDialog/Index.vue"),
    },
};
</script>

<style></style>
